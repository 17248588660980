import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { MaterialModule } from './material/material.module';
import { MapEditorModule } from './map-editor/map-editor.module';
import { OperationsModule } from './operations/operations.module';
import { AuthInterceptor } from './core/auth.interceptor';
import { OrderTrackingModule } from './order-tracking/order-tracking.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { RobotsModule } from './robots/robots.module';
import { environment } from '../environments/environment';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { UsersModule } from './users/users.module';
import { OrdersModule } from './orders/orders.module';
import { MatTableExporterModule } from 'mat-table-exporter';
import { OperatorTasksModule } from './operator-tasks/operator-tasks.module';
import { MappingModule } from './mapping/mapping.module';
import { SupervisedRobotsModule } from './supervised-robots/supervised-robots.module';
import { OperationManagementModule } from './operation-management/operation-management.module';
import { RobotOperatorOverviewModule } from './robot-operator-overview/robot-operator-overview.module';
import * as Sentry from '@sentry/angular-ivy';
import { MlPlatformModule } from './ml-platform/ml-platform.module';
import { CrossingMonitoringModule } from './crossing-monitoring/crossing-monitoring.module';
import { FieldOpsModule } from './field-ops/field-ops.module';

@NgModule({
  declarations: [AppComponent, LandingPageComponent],
  imports: [
    OperatorTasksModule,
    BrowserModule,
    BrowserAnimationsModule,
    RobotsModule,
    AppRoutingModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    MaterialModule,
    HttpClientModule,
    MatTableExporterModule,
    MapEditorModule,
    OperationsModule,
    OrdersModule,
    OrderTrackingModule,
    UsersModule,
    MappingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerImmediately',
    }),
    SupervisedRobotsModule,
    OperationManagementModule,
    RobotOperatorOverviewModule,
    MlPlatformModule,
    CrossingMonitoringModule,
    FieldOpsModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      // This will add tracing automatically to modules of modules lazy loaded
      // https://angular.io/api/core/APP_INITIALIZER
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
