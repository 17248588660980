import { Component, OnInit } from '@angular/core';
import { BackendService } from '../core/backend.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '../core/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RobotDto } from '../core/robots-service/backend/robot.dto';
import { firstValueFrom } from 'rxjs';
import { Location } from '@angular/common';
import { RobotIssue, RobotIssueSeverity } from '../core/robot-issues';

const issueReportUrl =
  'https://forms.clickup.com/9004035169/f/8caxb31-18284/FXS8PQO5NSAHF2H9FX?';

@Component({
  selector: 'app-field-ops-view',
  templateUrl: './field-ops-view.component.html',
  styleUrls: ['./field-ops-view.component.sass'],
})
export class FieldOpsViewComponent implements OnInit {
  displayedColumns = ['id', 'name'];

  serialNumber?: number;
  robots: RobotDto[] = [];
  selectedRobot?: RobotDto;

  constructor(
    private backendService: BackendService,
    private sanitizer: DomSanitizer,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
  ) {
    firstValueFrom(this.backendService.get<RobotDto[]>(`/robots/`)).then(
      (robots) => {
        this.robots = robots;
        this.selectedRobot = this.robots.find(
          (robot) => robot.serialNumber === this.serialNumber,
        );
      },
    );

    this.route.queryParams.subscribe(async (params) => {
      this.updateSerialNumber(params['serial-number']);
    });
  }

  ngOnInit(): void {
    return;
  }

  updateSerialNumber(serialNumberString?: string) {
    console.log(serialNumberString);
    const serialNumber = Number(serialNumberString);
    this.serialNumber = Number.isInteger(serialNumber)
      ? serialNumber
      : undefined;
    console.log(serialNumberString, this.serialNumber, this.robots.length);
    this.location.replaceState(`field-ops?serial-number=${this.serialNumber}`);
    this.selectedRobot = this.robots.find(
      (robot) => robot.serialNumber === this.serialNumber,
    );
  }

  superviseRobot() {
    if (!this.selectedRobot) {
      return;
    }
    this.router.navigate(['/robots/supervise/', this.selectedRobot.id], {
      queryParams: { active: this.selectedRobot.id },
    });
  }

  openIssue(issue: RobotIssue) {
    const issueId = issue.customId || issue.id;
    window.location.href = 'https://app.clickup.com/t/9004035169/' + issueId;
  }

  reportIssue() {
    if (!this.selectedRobot) {
      return;
    }

    const operationParams = this.selectedRobot.assignedOperationId
      ? `Operation%20ID=${this.selectedRobot.assignedOperationId}&`
      : '';
    const serialNumber = `Cart%20%23=${this.selectedRobot.serialNumber}&`;
    window.location.href = issueReportUrl + serialNumber + operationParams;
  }

  async unlockRobot() {
    if (!this.selectedRobot) {
      return;
    }
    firstValueFrom(
      this.backendService.post(
        `/robots/${this.selectedRobot.id}/open-compartments`,
        {},
      ),
    );
  }

  getIssueColor(issue: RobotIssue): string {
    switch (issue.severity) {
      case RobotIssueSeverity.BREAKING:
        return 'red';
      case RobotIssueSeverity.DEGRADED:
        return 'orange';
      case RobotIssueSeverity.INFORMATIONAL:
        return 'green';
      default:
        return 'magenta';
    }
  }
}
