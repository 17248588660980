<app-toolbar> </app-toolbar>

<div class="container">
  <mat-form-field appearance="fill" class="serial-number-input">
    <mat-label>Serial Number</mat-label>
    <input
      matInput
      type="number"
      (input)="updateSerialNumber($event.target.value)"
      [(ngModel)]="serialNumber"
      placeholder="Example: 300"
    /><button
      *ngIf="serialNumber"
      matSuffix
      mat-icon-button
      aria-label="Clear"
      (click)="serialNumber = undefined"
    >
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>

  <div class="action-buttons-container">
    <button
      *ngIf="selectedRobot"
      mat-raised-button
      color="primary"
      (click)="superviseRobot()"
      class="action-button"
    >
      Control robot
    </button>

    <button
      *ngIf="selectedRobot"
      mat-raised-button
      color="primary"
      (click)="reportIssue()"
      class="action-button"
    >
      Report Issue
    </button>

    <button
      *ngIf="selectedRobot"
      mat-raised-button
      color="primary"
      (click)="unlockRobot()"
      class="action-button"
    >
      Unlock
    </button>
  </div>

  <div *ngIf="!selectedRobot">Robot not found</div>
  <div class="spinner-background" *ngIf="!robots?.length">
    <mat-spinner></mat-spinner>
  </div>

  <table
    class="robot-issues-table"
    mat-table
    [dataSource]="selectedRobot.issues"
    *ngIf="selectedRobot"
  >
    <ng-container matColumnDef="id">
      <!--th
        mat-header-cell
        class="robot-issues-table-id-column"
        *matHeaderCellDef
      >
        ID
      </th-->
      <td mat-cell *matCellDef="let issue">
        <button
          mat-raised-button
          (click)="openIssue(issue)"
          class="issue-button {{ issue.severity }}"
        >
          {{ issue.customId | default : issue.id }}
        </button>
      </td>
    </ng-container>
    <ng-container matColumnDef="name">
      <!--th mat-header-cell *matHeaderCellDef>Summary</th-->
      <td mat-cell *matCellDef="let issue">{{ issue.name }}</td>
    </ng-container>

    <!--tr mat-header-row *matHeaderRowDef="displayedColumns"></tr-->
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
